import React, { useEffect, useState } from "react";
import { CustomCalenderInput, CustomDropDown, CustomForm, CustomInput, CustomInputphone, CustomMultiSelect } from "../../shared/Input/AllInputs";
import PrimaryButton from "../../shared/Button/PrimaryButton";

import { allValidations } from "../../utils/formValidations";

import CustomUploadFile from "../../components/CustomUploadFile";
import { uploadImages } from "../../utils/uploadFiles";
import { createUser, updateUser, userDetails } from "../../redux/actions/UsersAction";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { languageOptions, yesNoOptions } from "../../utils/constant";
import { showFormErrors } from "../../utils/commonFunctions";

const UserForm = ({ type }) => {
    const dispatch = useDispatch();
    let history = useHistory();

    const { id } = useParams();

    const [ImageFile, setImageFile] = useState();
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        speakOtherLanguage: false,
        otherLanguages: [],
    });

    const ignore = [];

    useEffect(() => {
        if (id) {
            dispatch(
                userDetails(id, (data) => {
                    setData({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        phone: data.phone,
                        speakOtherLanguage: data.speakOtherLanguage,
                        otherLanguages: data.otherLanguages,
                    });
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const [loading, setLoading] = useState(false);

    const handleChange = ({ name, value }) => {
        if (type !== "VIEW") {
            if (name === "speakOtherLanguage" && !value) {
                ignore.push("otherLanguages");
                data.otherLanguages = [];
            }
            const formErrors = allValidations(name, value, data, ignore);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = async () => {
        if (!data.speakOtherLanguage) {
            ignore.push("otherLanguages");
            data.otherLanguages = [];
        }
        console.log("reached", showFormErrors(data, setData, ignore), data);
        if (showFormErrors(data, setData, ignore)) {
            if (type === "ADD") {
                dispatch(createUser(data, setLoading, history));
            }
            if (type === "EDIT") {
                dispatch(updateUser(id, data, setLoading, history));
            }
        }
    };

    return (
        <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
            {" "}
            <div className="shadow-2 p-3 flex flex-column justify-content-center">
                <div className="grid">
                    <CustomInput label="First Name" name="firstName" data={data} onChange={handleChange} col={6} />
                    <CustomInput label="Last Name" name="lastName" data={data} onChange={handleChange} col={6} />
                    <CustomInput disabled={type !== "ADD"} label="Email" name="email" data={data} onChange={handleChange} col={6} />
                    <CustomInputphone label="Mobile Number" name="phone" value={data?.phone} data={data} onChange={handleChange} col={6} />
                    <CustomDropDown
                        options={yesNoOptions}
                        optionLabel="name"
                        col={12}
                        showFilter={false}
                        label={"Do you speak a language other than English at home?"}
                        name="speakOtherLanguage"
                        data={data}
                        onChange={handleChange}
                    ></CustomDropDown>
                    {data.speakOtherLanguage ? (
                        <CustomMultiSelect
                            options={languageOptions}
                            optionLabel=""
                            col={12}
                            showFilter={false}
                            label={"What language other than English do you speak at home?"}
                            name="otherLanguages"
                            data={data}
                            onChange={handleChange}
                        ></CustomMultiSelect>
                    ) : null}
                </div>
                <div className=" flex jutify-content-end w-full">
                    <div className="col-2"> {type !== "VIEW" && <PrimaryButton label="Save" loading={loading} onClick={onSubmit} col={6} />} </div>
                </div>
            </div>
        </CustomForm>
    );
};

export default UserForm;
