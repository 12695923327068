import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveInactiveUsers } from "../../redux/actions/UsersAction";
import { getEventsCount } from "../../redux/actions/eventAction";

export default function Dashboard({ name, history }) {
    const dispatch = useDispatch();
    const usersCount = useSelector((state) => state.allUsers.usersCount);
    const eventsCount = useSelector((state) => state.allEvents.eventsCount);

    useEffect(() => {
        dispatch(getActiveInactiveUsers());
        dispatch(getEventsCount());
    }, [dispatch]);

    return (
        <div className="grid">
            <div className="col-12 lg:col-4 cursor-pointer" onClick={() => history.push("/users")}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Active Users</span>
                            <div className="text-900 font-medium text-xl">{usersCount?.activeUsers ?? 0}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className="pi pi-users text-cyan-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-500">Total Active Users</span>
                </div>
            </div>
            <div className="col-12 lg:col-4 cursor-pointer" onClick={() => history.push("/users")}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Inactive Users</span>
                            <div className="text-900 font-medium text-xl">{usersCount?.inActiveUsers ?? usersCount}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-red-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className="pi pi-user-minus text-red-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-500">Total Inactive Users</span>
                </div>
            </div>
            <div className="col-12 lg:col-4 cursor-pointer" onClick={() => history.push("/events")}>
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">No. of Events</span>
                            <div className="text-900 font-medium text-xl">{eventsCount}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-green-300 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className="pi pi-calendar text-white text-xl" />
                        </div>
                    </div>
                    <span className="text-500">Total Events</span>
                </div>
            </div>
            {/* <EnquiryDesc />
            <NewBussinessList /> */}
        </div>
    );
}
