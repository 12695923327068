import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import { confirmPopup } from "primereact/confirmpopup";
import { useDispatch } from "react-redux";
import { deleteEventRequest, getRequestEvents } from "../../redux/actions/eventAction";

export const EventRequestsTable = () => {
    const dispatch = useDispatch();
    const [requestEvent, setRequestEvent] = useState();
    const deleteRow = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                dispatch(deleteEventRequest(id, setRequestEvent));
            },
            reject: () => {},
        });
    };
    useEffect(() => {
        dispatch(getRequestEvents(setRequestEvent));
    }, [dispatch]);

    const actions = (data) => {
        return (
            <div className="flex justify-content-around">
                {/* <i className="pi pi-pencil cursor-pointer" onClick={() => history.push(`/users/edit/${data._id}`)}></i> */}
                <i className="pi pi-trash cursor-pointer" onClick={(e) => deleteRow(e, data._id)}></i>
            </div>
        );
    };

    const activeBodyTemplate = (rowData) => {
        return (
            <i className={classNames("pi", { "true-icon pi-check-circle": rowData.isActive, "false-icon pi-times-circle": !rowData.isActive })}></i>
        );
    };

    return (
        <>
            {/* <TableCard title="Event Requests" buttonTitle="Add Event" linkTo={`/event-requests/add`}> */}
            <DataTable paginator value={requestEvent} showGridlines rows={10} emptyMessage="No event requests found.">
                <Column field="firstName" sortable header="First Name"></Column>
                <Column field="lastName" sortable header="Last Name"></Column>
                <Column field="email" sortable header="Email"></Column>
                <Column field="phone" sortable header="Mobile Number"></Column>
                <Column
                    field=""
                    body={(e) => {
                        return (
                            <div>
                                {e?.country?.name},{e?.state?.name},{e?.city}
                            </div>
                        );
                    }}
                    sortable
                    header="Address"
                ></Column>
                <Column field="postCode" sortable header="Post Code"></Column>
                <Column field="requestType" sortable header="Request Type"></Column>
                <Column field="comments" sortable header="Comments"></Column>

                <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
            </DataTable>
            {/* </TableCard> */}
        </>
    );
};
