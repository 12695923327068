export const types = {
    SHOW_TOAST: "SHOW_TOAST",
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    SHOW_SIDEBAR_LOADER: "SHOW_SIDEBAR_LOADER",
    HIDE_SIDEBAR_LOADER: "HIDE_SIDEBAR_LOADER",
    USER_DETAIL: "USER_DETAIL",
    USERS: "USERS",
    USERS_COUNT: "USERS_COUNT",
    REQUEST_EVENTS: "Request_Event",
    DELETE_EVENT_REQUEST: "DELETE_EVENT_REQUEST",
    EVENTS: "Events",
    EVENT: "EVENT",
    EVENTS_COUNT: "EVENTS_COUNT"
};
