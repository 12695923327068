export default class Constants {
    static END_POINT = {
        LOGIN: "/sign-in",
        PROFILE: "/profile",
        FORGET_PASSWORD: "/forget-password",
        UPDATE_PROFILE: "/update",
        RESET_PASSWORD: "/change-forget-password",
        CHANGE_PASSWORD: "/change-password",
        //Dashborad
        GET_USERS_COUNT: "/user/count",
        NOTIFICATION: "/notification",
        CHANGE_APP_DESC: "/constant",
        //Users
        USERS: "/users/",
        //Events
        GET_EVENTS: "/events/",
        GET_REQUEST_EVENTS: "/event-reuquests/",
        GET_ORGANIZERS: "/organizers",
        ACTIVE_INACTIVE_USERS: '/users-count',
        EVENTS_COUNT: '/events-count'
    };
}
