import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { useHistory } from "react-router-dom";
import { confirmPopup } from "primereact/confirmpopup";
import { useDispatch } from "react-redux";
import { getUsers, updateUser } from "../../redux/actions/UsersAction";
import { useSelector } from "react-redux";

export const UserTable = () => {
    let history = useHistory();
    let dispatch = useDispatch();
    const allUsers = useSelector((state) => state.allUsers.allUsers);
    const [loading, setLoading] = useState(false);

    const deleteRow = (e, id) => {
        confirmPopup({
            target: e.currentTarget,
            message: "Are you sure you want to delete?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                dispatch(updateUser(id, { isDeleted: true, isActive: false }, setLoading));
            },
            reject: () => {},
        });
    };

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);
    const actions = (data) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-pencil cursor-pointer" onClick={() => history.push(`/users/edit/${data._id}`)}></i>
                <i className="pi pi-trash cursor-pointer" onClick={(e) => deleteRow(e, data._id)}></i>
            </div>
        );
    };

    const activeBodyTemplate = (rowData) => {
        return <>{rowData.isActive ? "Active" : "Inactive"}</>;
    };

    const otherLanguages = (rowData) => {
        return (
            <>
                <div>{rowData?.otherLanguages?.join(", ")}</div>
            </>
        );
    };

    return (
        <>
            <TableCard title="Users List" buttonTitle="Add Users" linkTo={`/users/add`}>
                <DataTable paginator value={allUsers} showGridlines rows={10} emptyMessage="No user found.">
                    {/* <Column field="icon" header="Icon" body={imageBodyTemplate}></Column> */}
                    <Column field="firstName" sortable header="First Name"></Column>
                    <Column field="lastName" sortable header="Last Name"></Column>
                    <Column field="email" sortable header="Email"></Column>
                    <Column field="phone" sortable header="Mobile Number"></Column>
                    {/* <Column field={(e) => (e.organizerType === "Clubs/Groups" ? e.organizationName : "")} sortable header="Club/Groups"></Column>
                    <Column field={(e) => (e.organizerType === "School" ? e.organizationName : "")} sortable header="School Name"></Column> */}
                    <Column body={otherLanguages} sortable header="Foreign Languages Type"></Column>
                    <Column field="isActive" header="Status" dataType="boolean" style={{ minWidth: "6rem" }} body={activeBodyTemplate} />
                    <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
                </DataTable>
            </TableCard>
        </>
    );
};
