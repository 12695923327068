import moment from 'moment-timezone';

export const LevelOptions = [
    {
        name: "Beginner",
        value: "Beginner",
    },
    {
        name: "Experienced",
        value: "Experienced",
    },
];

export const MeditationType = [
    {
        name: "Breathwork Meditation",
        value: "Breathwork Meditation",
    },
    {
        name: "Visualization Meditation",
        value: "Visualization Meditation",
    },
    {
        name: "Guided Meditation",
        value: "Guided Meditation",
    },
    {
        name: "Reiki Meditation",
        value: "Reiki Meditation",
    },
];

export const GenderOptions = [
    { name: "Male", value: "MALE" },
    { name: "Female", value: "FEMALE" },
    { name: "Other", value: "OTHER" },
    { name: "Nothing", value: "NOTHING" },
];

export const RecursionOptions = [
    { name: "One Time", value: "ONE_TIME" },
    { name: "Recurring", value: "RECURRING" }
];

export const RecursionPeriodOptions = [
    { name: "Weekly", value: "WEEKLY" },
    { name: "Twice a Week", value: "TWICE_A_WEEK" },
    { name: "Monthly", value: "MONTHLY" },
    { name: "Yearly", value: "YEARLY" }
];

export const yesNoOptions = [
    { name: "Yes", value: true },
    { name: "No", value: false },
];

export const languageOptions = [
    "Mandarin",
    "Arabic",
    "Cantonese",
    "Vietnamese",
    "Italian",
    "Greek",
    "Hindi",
    "Spanish",
    "Punjabi",
    "Other"
];

export const weekDays = [
    { day: 0, name: "Sunday" },
    { day: 1, name: "Monday" },
    { day: 2, name: "Tuesday" },
    { day: 3, name: "Wednesday" },
    { day: 4, name: "Thursday" },
    { day: 5, name: "Friday" },
    { day: 6, name: "Saturday" }
];

export const getTimezones = () => {
    const timezones = moment.tz.names();
    return timezones;
};