import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
// import UploadImg from "../../assets/images/upload-cloud.png";
// import sampleEmployeesCSV from "../../assets/files/sample_employees.csv";

export default function CustomUploadFile({ name, ImageFile, setImageFile }) {
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const [temp, setTemp] = useState({ fn: () => null });
    const fileUploadRef = useRef(null);
    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        const reader = new FileReader();
        reader.onload = (e) => {
            const fileContent = e.target.result;
            const csvHeaders = fileContent.split("\n")[0].split(",");
        };
        reader.readAsText(e.files[0]);

        setImageFile(e.files[0]);

        setTotalSize(_totalSize);
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
        setImageFile("");
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { chooseButton } = options;

        return <>{ImageFile ? "" : <div className="w-full text-center p-2">{chooseButton}</div>}</>;
    };

    useEffect(() => {
        if (ImageFile === "") {
            temp.fn();
        }
    }, [ImageFile]);

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap justify-content-between">
                <div className="flex align-items-center" style={{ width: "40%" }}>
                    <i className="pi pi-file text-5xl" />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <div className="flex align-items-center gap-3">
                    <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                    <Button
                        type="button"
                        icon="pi pi-times"
                        className="p-button-outlined px-3 p-button-rounded p-button-danger ml-auto"
                        onClick={() => onTemplateRemove(file, props.onRemove)}
                    />
                    {/* <Button
                        type="button"
                        icon="pi pi-check"
                        className="p-button-outlined px-3 p-button-rounded p-button-success ml-auto"
                        onClick={() => {}}
                    /> */}
                </div>
            </div>
        );
    };

    const emptyTemplate = (options) => {
        const { className, chooseButton } = options;

        return (
            <>
                {ImageFile ? (
                    ""
                ) : (
                    <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}>
                        <div className="w-full flex align-items-center flex-column ">
                            {/* <img src={UploadImg} alt="" /> */}
                            <span style={{ fontSize: "1.2em", color: "var(--text-gray-100)" }} className="mt-5">
                                Select a file or drag and drop here
                            </span>
                            <p>JPG or PNG file size no more than 10MB</p>
                            <small>
                                The pixel size for a 16:9 aspect ratio depends on the resolution you're aiming for. For example, it could be 1280 x
                                720 pixels{" "}
                            </small>
                            {chooseButton}
                        </div>
                    </div>
                )}
            </>
        );
    };

    const chooseOptions = { icon: "pi ", iconOnly: false, label: "Select file", className: "custom-choose-btn p-button-rounded p-button-outlined" };
    const uploadOptions = {
        icon: "pi pi-fw pi-cloud-upload",
        iconOnly: true,
        className: "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
    };
    const cancelOptions = {
        icon: "pi pi-fw pi-times",
        iconOnly: true,
        className: "custom-cancel-btn  p-button-danger p-button-rounded p-button-outlined",
    };

    return (
        <div className="custom-Image-upload my-3 relative ">
            <span className=" absolute bg-grident mx-5 text-base font-semibold" style={{ marginTop: "-11px" }}>
                Image
            </span>
            <Toast ref={toast}></Toast>
            <div className="border-1 border-round-xl p-4 border-gray-300 bg-white">
                <FileUpload
                    className=""
                    contentClassName="p-0"
                    ref={fileUploadRef}
                    name={name}
                    accept=".jpg,.png"
                    maxFileSize={10485760}
                    emptyTemplate={emptyTemplate}
                    onSelect={onTemplateSelect}
                    onError={onTemplateClear}
                    onClear={onTemplateClear}
                    headerTemplate={headerTemplate}
                    itemTemplate={itemTemplate}
                    chooseOptions={chooseOptions}
                    uploadOptions={uploadOptions}
                    cancelOptions={cancelOptions}
                />
            </div>
        </div>
    );
}
