import api from "../../services/api";
import Constants from "../../services/constant";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { types } from "../types/types";
import { uploadFile } from "../../utils/uploadFiles";
import { showToast } from "./toastAction";

const createUser = (data, setLoading, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);

    const res = await api("post", Constants.END_POINT.USERS, { ...data });
    if (res.success) {
        history.push("/users");
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }

    setLoading(false);
    dispatch(hideLoaderAction());
};

const getUsers = () => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.USERS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.USERS,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const getActiveInactiveUsers = () => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.ACTIVE_INACTIVE_USERS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.USERS_COUNT,
                payload: res.data,
            });
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    dispatch(hideLoaderAction());
};

const userDetails = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", `${Constants.END_POINT.USERS}${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.USER_DETAIL,
                payload: res.data,
            });
            if (returnData) {
                returnData(res.data);
            }
        }
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }

    dispatch(hideLoaderAction());
};

const updateUser = (id, data, setLoading, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    setLoading(true);

    const res = await api("put", `${Constants.END_POINT.USERS}${id}`, data);
    if (res.success) {
        if (history) history.push("/users");
        else dispatch(getUsers());
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
    setLoading(false);
    dispatch(hideLoaderAction());
};

export { createUser, getUsers, userDetails, updateUser, getActiveInactiveUsers };
