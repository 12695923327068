import { types } from "../types/types";

const intitalState = {
    allEvents: [],
    event: {},
    eventsCount: 0
};

const EventsReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EVENTS:
            return {
                ...state,
                allEvents: action.payload,
            };
        case types.EVENT:
            return {
                ...state,
                event: action.payload,
            };
        case types.EVENTS_COUNT:
            return {
                ...state,
                eventsCount: action.payload,
            };
        default:
            return { ...state };
    }
};

export default EventsReducer;
