import { equal, length } from "./javascript";
import { firstLetterToUppercase, emailValidation, number, passwordValidation, otpCodeValidation } from "./regex";

export const allValidations = (name, value, state, ignore = []) => {
    const formErrors = { ...state.formErrors };
    if (ignore.includes(name)) {
        if (formErrors[name]) formErrors[name] = "";
        return formErrors;
    }
    switch (name) {
        case "email":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else if (!emailValidation(value)) {
                formErrors[name] = `Please enter valid email!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "password":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
                // } else if (!passwordValidation(value)) {
                //     formErrors[
                //         name
                //     ] = `Please enter a password with 8-16 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "name":
        case "firstName":
        case "lastName":
        case "title":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "image":
        case "date":
            if (!value || value === "" || equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;

        case "otpcode":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else if (!otpCodeValidation(value)) {
                formErrors[name] = `Enter Valid OTP!`;
            } else {
                formErrors[name] = "";
            }
            break;
        // case "confirmPassword":
        //     if (equal(length(value))) {
        //         formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
        //     } else if (!customPasswordCheck(value)) {
        //         formErrors[name] = `Enter valid password`;
        //     } else {
        //         formErrors[name] = "";
        //     }
        //     break;
        case "eventOrganizer":
        case "city":
        case "postCode":
        case "loopDistance":
            if (!value || !value.toString().length) {
                formErrors[name] = `${firstLetterToUppercase(name)} are required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "level":
        case "price":
        case "days":
        case "type":
        case "time":
        case "description":
            if (!value) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "phone":
            if (!value || value.length <= 4) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "otherLanguages":
            if (!value || !value.length) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "speakOtherLanguage":
            if (!JSON.stringify(value)) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        default:
            break;
    }

    return formErrors;
};
